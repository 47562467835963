<template>
  <Head>
    <title>Выбор корпуса {{ $titleEnd }}</title>
    <meta
      name="description"
      content="Выбор корпуса и этажа в нем при покупке жилья в ЖК VERY на ул Ботаническая 29 в Москве от ГК ОСНОВА."
    />
    <meta property="og:title" :content="'Выбор корпуса ' + $titleEnd" />
    <meta
      property="og:description"
      content="Выбор корпуса и этажа в нем при покупке жилья в ЖК VERY на ул Ботаническая 29 в Москве от ГК ОСНОВА."
    />
    <meta
      property="og:image"
      content="https://api.gkosnova.tech/public/storage/media/2022/2/1200/oXzorkK8sRMBrx0yMrKq191wHUQIZLqNza1j99ke.jpg"
    />
  </Head>
  <main class="main my-0">
    <div class="plan-archive">
      <div class="top text-white d-none d-sm-block">
        <div class="container">
          <Breadcrumbs
            :links="[
              { name: 'Home', text: 'Главная' },
              { text: 'Выбор корпуса' },
            ]"
          />
          <h1 ref="title" class="h2">
            Выбор корпуса <br />
            в&nbsp;ЖК&nbsp;Very
          </h1>
          <div ref="btn">
            <router-link
              :to="{ name: 'PlanSearch' }"
              class="btn btn-small btn-yellow"
            >
              <Icon icon="settings" />Подбор по параметрам
            </router-link>
          </div>
        </div>
      </div>
      <Buildings />
    </div>
  </main>
</template>

<script>
import Buildings from "@/components/planArchive/Buildings.vue";
import Breadcrumbs from "@/components/UI/Breadcrumbs.vue";
import { gsap } from "gsap";
import { Head } from "@vueuse/head";
export default {
  components: {
    Head,
    Buildings,
    Breadcrumbs,
  },
  data() {
    return {
      dmpLoaded: false,
    };
  },
  mounted() {
    gsap.from(gsap.utils.toArray([this.$refs.title, this.$refs.btn]), {
      autoAlpha: 0,
      y: 30,
      stagger: 0.1,
    });
  },
};
</script>

<style scoped>
.plan-archive {
  position: relative;
}

.top {
  position: absolute;
  top: 110px;
  left: 0;
  z-index: 2;
  width: 100%;
  padding-right: 20px;
  padding-left: 20px;
  pointer-events: none;
}

.top h1,
.top .h1 {
  margin: 0 0 30px;
  line-height: 0.875;
}

.top .btn,
.top .breadcrumbs {
  pointer-events: auto;
}

:deep(.swiper-helper) {
  display: none;
}
</style>
